.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {

    .container,
    .container-sm {
        max-width: 540px
    }
}

@media (min-width:768px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 720px
    }
}

@media (min-width:992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px
    }
}

@media (min-width:1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px
    }
}

@media (min-width:1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1320px
    }
}


.feature_section .slide-arrow {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    border: 2px solid var(--black);
    line-height: 30px;
    text-align: center;
    color: var(--black);
    padding: 0;
    background-color: transparent;
    position: absolute;
    transform: translate(-50%,-50%);
    opacity: .5;
    z-index: 9
  }
  
  
  
  .product-img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 700px;
    width: 700px;
    object-fit: contain;
  }

  .img-fluid {
    max-width: 100%;
    height: auto
}
  
  .feature_big_title,.offer_big_title {
    font-size: 25vh;
    font-weight: 700;
    position: absolute;
    left: 50%;
    opacity: .1;
    top: 0;
    transform: translate(-50%);
    pointer-events: none;
    z-index: 1
  }
  
  .feature_small_title,.offer_big_title {
    font-size: 10vh;
    font-weight: 700;
    position: absolute;
    left: 50%;
    bottom: 0;
    opacity: .1;
    transform: translate(-50%);
    pointer-events: none;
    z-index: 1;
  }

  .feature_section {
    position: relative;
    background-color: #f5f5f5
  }
  
  @media (max-width: 575.98px) {
    .feature_big_title {
      font-size: 9vh;
    }
    .feature_small_title {
      font-size: 5vh;
    }
  }
  
  
  
  .slide-wrapper{
    position: relative;
  }
  
  .menu{
    position:absolute;
    top: 0;
    bottom: 0;
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
  }
  
  .menu .toggle{
    height:35px;
    width:35px;
    background: white;
    border-radius:50%;
    box-shadow:0 3px 4px rgba(0,0,0,0.15);
    display:flex;
    align-items:center;
    justify-content:center;
    color:#333;
    font-size:2rem;
    cursor:pointer;
    transition:1.25s;
    z-index:5;
  }
  
  
  
  
  .menu .toggle .fa-plus{
    animation: wave 0.5s infinite;
  }
  
  @keyframes wave{
    0% {transform: scale(1);}
    50% {transform: scale(1.1);}
    100% {transform: scale(1);}
  }
  
  /* raze pro */
  
  .menu .toggle.slide1-pos1 {
    position: absolute;
    left: 32%;
    top: 58%;
  }
  
  
  .menu .toggle.slide1-pos2{
    position: absolute;
    left: 42%;
    top: 44%;
  }
  
  .menu .toggle.slide1-pos3{
    position: absolute;
    left: 37%;
    top: 35%;
  }
  
  .menu .toggle.slide1-pos4{
    position: absolute;
    left: 58%;
    top: 58%;
  }
  
  .menu .toggle.slide1-pos5{
    position: absolute;
    left: 39%;
    top: 23%;
  }
  
  /* rook */
  
  .menu .toggle.slide2-pos1 {
    position: absolute;
    left: 34%;
    top: 58%;
  }
  
  .menu .toggle.slide2-pos2{
    position: absolute;
    left: 42%;
    top: 44%;
  }
  
  .menu .toggle.slide2-pos3{
    position: absolute;
    left: 37%;
    top: 39%;
  }
  
  .menu .toggle.slide2-pos4{
    position: absolute;
    left: 64%;
    top: 60%;
  }
  
  .menu .toggle.slide2-pos5{
    position: absolute;
    left: 39%;
    top: 22%;
  }
  
  .menu .toggle.slide2-pos6 {
    position: absolute;
    left: 54%;
    top: 50%;
  }
  
  /* larc */
  
  .menu .toggle.slide3-pos1 {
    position: absolute;
    left: 35%;
    top: 59%;
  }
  
  .menu .toggle.slide3-pos2{
    position: absolute;
    left: 44%;
    top: 44%;
  }
  
  .menu .toggle.slide3-pos3{
    position: absolute;
    left: 39.5%;
    top: 39%;
  }
  
  .menu .toggle.slide3-pos4{
    position: absolute;
    left: 64%;
    top: 60%;
  }
  
  .menu .toggle.slide3-pos5{
    position: absolute;
    left: 40%;
    top: 24%;
  }
  
  /* raze */
  
  .menu .toggle.slide4-pos1 {
    position: absolute;
    left: 36%;
    top: 60%;
  }
  
  
  .menu .toggle.slide4-pos2{
    position: absolute;
    left: 44%;
    top: 44%;
  }
  
  .menu .toggle.slide4-pos3{
    position: absolute;
    left: 38%;
    top: 39%;
  }
  
  .menu .toggle.slide4-pos4{
    position: absolute;
    left: 64%;
    top: 58%;
  }
  
  .menu .toggle.slide4-pos5{
    position: absolute;
    left: 40%;
    top: 25%;
  }
  
  /* ezen */
  
  .menu .toggle.slide5-pos1 {
    position: absolute;
    left: 36%;
    top: 60%;
  }
  
  
  .menu .toggle.slide5-pos2{
    position: absolute;
    left: 46%;
    top: 46%;
  }
  
  .menu .toggle.slide5-pos3{
    position: absolute;
    left: 39%;
    top: 39%;
  }
  
  .menu .toggle.slide5-pos4{
    position: absolute;
    left: 64%;
    top: 58%;
  }
  
  .menu .toggle.slide5-pos5{
    position: absolute;
    left: 42%;
    top: 24%;
  }
  
  /* elix */
  
  .menu .toggle.slide6-pos1 {
    position: absolute;
    left: 36%;
    top: 60%;
  }
  
  
  .menu .toggle.slide6-pos2{
    position: absolute;
    left: 46%;
    top: 46%;
  }
  
  .menu .toggle.slide6-pos3{
    position: absolute;
    left: 37%;
    top: 39%;
  }
  
  .menu .toggle.slide6-pos4{
    position: absolute;
    left: 64%;
    top: 58%;
  }
  
  .menu .toggle.slide6-pos5{
    position: absolute;
    left: 40%;
    top: 24%;
  }
  
  /* cosmo */
  
  .menu .toggle.slide7-pos1 {
    position: absolute;
    left: 36%;
    top: 52%;
  }
  
  
  .menu .toggle.slide7-pos2{
    position: absolute;
    left: 52%;
    top: 46%;
  }
  
  .menu .toggle.slide7-pos3{
    position: absolute;
    left: 59%;
    top: 39%;
  }
  
  .menu .toggle.slide7-pos4{
    position: absolute;
    left: 62%;
    top: 57%;
  }
  
  .menu .toggle.slide7-pos5{
    position: absolute;
    left: 58%;
    top: 24%;
  }
  
  /* lido */
  
  .menu .toggle.slide8-pos1 {
    position: absolute;
    left: 36%;
    top: 55%;
  }
  
  
  .menu .toggle.slide8-pos2{
    position: absolute;
    left: 52%;
    top: 46%;
  }
  
  .menu .toggle.slide8-pos3{
    position: absolute;
    left: 58%;
    top: 39%;
  }
  
  .menu .toggle.slide8-pos4{
    position: absolute;
    left: 62%;
    top: 57%;
  }
  
  .menu .toggle.slide8-pos5{
    position: absolute;
    left: 58%;
    top: 24%;
  }
  
  /* larc lite */
  
  .menu .toggle.slide9-pos1 {
    position: absolute;
    left: 36%;
    top: 58%;
  }
  
  
  .menu .toggle.slide9-pos2{
    position: absolute;
    left: 51%;
    top: 46%;
  }
  
  .menu .toggle.slide9-pos3{
    position: absolute;
    left: 57%;
    top: 39%;
  }
  
  .menu .toggle.slide9-pos4{
    position: absolute;
    left: 62%;
    top: 57%;
  }
  
  .menu .toggle.slide9-pos5{
    position: absolute;
    left: 58%;
    top: 24%;
  }
  
  @media (max-width: 767px) {
    .menu .toggle {
      height: 30px;
      width: 30px;
      font-size: 15px;
    }
  
    .menu .toggle.slide1-pos1 {
      left: 17%;
      top: 56%;  
    }
  
    .menu .toggle.slide1-pos2{
      left: 35%;
      top: 46%;
    }
  
    .menu .toggle.slide1-pos3{
      left: 22%;
      top: 41%;
    }
    
    .menu .toggle.slide1-pos4{
      left: 70%;
      top: 56%;
    }
  
    .menu .toggle.slide1-pos5{
      left: 26%;
      top: 32%;
    }
  
    /* rook */
  
    .menu .toggle.slide2-pos1 {
      left: 17%;
      top: 56%;  
    }
  
    .menu .toggle.slide2-pos2{
      left: 35%;
      top: 46%;
    }
  
    .menu .toggle.slide2-pos3{
      left: 22%;
      top: 41%;
    }
    
    .menu .toggle.slide2-pos4{
      left: 74%;
      top: 56%;
    }
  
    .menu .toggle.slide2-pos5{
      left: 26%;
      top: 30%;
    }
    
    .menu .toggle.slide2-pos6{
      left: 60%;
      top: 50%;
    }
  
    /* larc */
  
    .menu .toggle.slide3-pos1 {
      left: 17%;
      top: 56%;  
    }
  
    .menu .toggle.slide3-pos2{
      left: 39%;
      top: 48%;
    }
  
    .menu .toggle.slide3-pos3{
      left: 26%;
      top: 41%;
    }
    
    .menu .toggle.slide3-pos4{
      left: 75%;
      top: 56%;
    }
  
    .menu .toggle.slide3-pos5{
      left: 29%;
      top: 32%;
    }
  
    /* raze */
  
    .menu .toggle.slide4-pos1 {
      left: 17%;
      top: 56%;  
    }
  
    .menu .toggle.slide4-pos2{
      left: 35%;
      top: 46%;
    }
  
    .menu .toggle.slide4-pos3{
      left: 24%;
      top: 41%;
    }
    
    .menu .toggle.slide4-pos4{
      left: 75%;
      top: 56%;
    }
  
    .menu .toggle.slide4-pos5{
      left: 26%;
      top: 32%;
    }
  
    /* ezen */
  
    .menu .toggle.slide5-pos1 {
      left: 17%;
      top: 56%;  
    }
  
    .menu .toggle.slide5-pos2{
      left: 40%;
      top: 47%;
    }
  
    .menu .toggle.slide5-pos3{
      left: 26%;
      top: 41%;
    }
    
    .menu .toggle.slide5-pos4{
      left: 75%;
      top: 56%;
    }
  
    .menu .toggle.slide5-pos5{
      left: 32%;
      top: 32%;
    }
  
    /* elix */
  
    .menu .toggle.slide6-pos1 {
      left: 17%;
      top: 56%;  
    }
  
    .menu .toggle.slide6-pos2{
      left: 40%;
      top: 47%;
    }
  
    .menu .toggle.slide6-pos3{
      left: 26%;
      top: 41%;
    }
    
    .menu .toggle.slide6-pos4{
      left: 75%;
      top: 56%;
    }
  
    .menu .toggle.slide6-pos5{
      left: 32%;
      top: 32%;
    }
  
    /* cosmo */
  
    .menu .toggle.slide7-pos1 {
      left: 19%;
      top: 54%;  
    }
  
    .menu .toggle.slide7-pos2{
      left: 55%;
      top: 45%;
    }
  
    .menu .toggle.slide7-pos3{
      left: 68%;
      top: 41%;
    }
    
    .menu .toggle.slide7-pos4{
      left: 75%;
      top: 56%;
    }
  
    .menu .toggle.slide7-pos5{
      left: 65%;
      top: 32%;
    }
  
    /* lido */
  
    .menu .toggle.slide8-pos1 {
      left: 19%;
      top: 54%;  
    }
  
    .menu .toggle.slide8-pos2{
      left: 55%;
      top: 45%;
    }
  
    .menu .toggle.slide8-pos3{
      left: 68%;
      top: 41%;
    }
    
    .menu .toggle.slide8-pos4{
      left: 75%;
      top: 56%;
    }
  
    .menu .toggle.slide8-pos5{
      left: 65%;
      top: 32%;
    }
  
    /* larc lite */
  
    .menu .toggle.slide9-pos1 {
      left: 19%;
      top: 54%;  
    }
  
    .menu .toggle.slide9-pos2{
      left: 55%;
      top: 45%;
    }
  
    .menu .toggle.slide9-pos3{
      left: 68%;
      top: 41%;
    }
    
    .menu .toggle.slide9-pos4{
      left: 75%;
      top: 56%;
    }
  
    .menu .toggle.slide9-pos5{
      left: 65%;
      top: 32%;
    }
  
    
  }
  
  
  .modal-open {
    overflow: hidden
  }
  
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0
  }
  
  .modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
  }
  
  .modal.fade .modal-dialog {
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
    -webkit-transform: translate(0,-50px);
    transform: translate(0,-50px)
  }
  
  @media (prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
  }
  
  .modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none
  }
  
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    transform: scale(1.02)
  }
  
  .modal-dialog-scrollable {
    display: -ms-flexbox;
    display: flex;
    max-height: calc(100% - 1rem)
  }
  
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden
  }
  
  .modal-dialog-scrollable .modal-footer,.modal-dialog-scrollable .modal-header {
    -ms-flex-negative: 0;
    flex-shrink: 0
  }
  
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto
  }
  
  .modal-dialog-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem)
  }
  
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: ""
  }
  
  .modal-dialog-centered.modal-dialog-scrollable {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%
  }
  
  .modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none
  }
  
  .modal-dialog-centered.modal-dialog-scrollable::before {
    content: none
  }
  
  .modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0
  }
  
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000
  }
  
  .modal-backdrop.fade {
    opacity: 0
  }
  
  .modal-backdrop.show {
    opacity: .5
  }
  
  .modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #ffb03b;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px)
  }
  
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto
  }
  
  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    color: #fff;
  }
  
  .modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
    font-weight: normal;
  }
  
  .modal-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px)
  }
  
  .modal-footer>* {
    margin: .25rem
  }
  
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
  }
  
  @media (min-width: 576px) {
    .modal-dialog {
        max-width:500px;
        margin: 1.75rem auto
    }
  
    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem)
    }
  
    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem)
    }
  
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }
  
    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content
    }
  
    .modal-sm {
        max-width: 300px
    }
  }
  
  @media (min-width: 992px) {
    .modal-lg,.modal-xl {
        max-width:800px
    }
  }
  
  @media (min-width: 1200px) {
    .modal-xl {
        max-width:1140px
    }
  }

  .carousel {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block
}

.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
    transform: translateX(100%)
}

.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
}

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {

    .carousel-fade .active.carousel-item-end,
    .carousel-fade .active.carousel-item-start {
        transition: none
    }
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {

    .carousel-control-next,
    .carousel-control-prev {
        transition: none
    }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-indicators [data-bs-target] {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center
}

.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
    filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000
}

.carousel-dark .carousel-caption {
    color: #000
}

[data-bs-theme=dark] .carousel .carousel-control-next-icon,
[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon,
[data-bs-theme=dark].carousel .carousel-control-prev-icon {
    filter: invert(1) grayscale(100)
}

[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target],
[data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
    background-color: #000
}

[data-bs-theme=dark] .carousel .carousel-caption,
[data-bs-theme=dark].carousel .carousel-caption {
    color: #000
}
  
.list-ul {
  list-style-type: disc;
  padding-left: 20px;
}

.list-li {
  margin-bottom: 5px;
}

  
  
  