
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {

    .container,
    .container-sm {
        max-width: 540px
    }
}

@media (min-width:768px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 720px
    }
}

@media (min-width:992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px
    }
}

@media (min-width:1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px
    }
}

@media (min-width:1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1320px
    }
}
  
  
  .card {
    position: relative;
    background-color: #283e8c;
    width: 1500px;
    height: 700px;
    perspective: 1000px;
    border-radius: 20px;
    border: 5px solid #e6e6e6;
    box-shadow: 0 20px 40px -10px rgba(0, 0, 0, 0.3); /* Adjusted box shadow */
    transition: 0.4s ease-in-out transform, 0.4s ease-in-out box-shadow; /* Added box-shadow transition */
  }
  
  .card:hover {
    transform: translate3d(0px, 0px, -250px);
    /* box-shadow: 20px 20px 20px 20px rgba(185, 3, 134, 0.5); Increased box shadow on hover */
  }
  
  
  .flex-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  .card-image {
    width: 260px;
    height: 180px;
  }
  
  .title {
    font-size: 1.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    color: white;
    text-align: center;
  }
  
  .rook {
    margin-top: 30px;
  }
  
  .heading {
    font-size: 2.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    color: white;
    text-align: center;
    
  }
  
  .spec-desc {
    font-size: 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: white;
    text-align: center;
  }
  
  .speedo-text {
    font-size: 1.7rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    color: white;
    text-align: center;
  }
  .img {
    width: 480px;
    height: 96px;
  }
  
  @media (max-width: 567px) {
    .card {
      width: 500px;
      height: auto;
    }
    .flex-container {
      flex-direction: column;
      align-items: center;
    }
    
  }